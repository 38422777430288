<template>
  <div class="mm-main-page">
    <Header
      :hide-search="!isSearchGroupAccess"
      is-error-page
    />
    <slot />
    <Footer />
    <ModalsContainer />
  </div>
</template>

<script lang="ts" setup>
import Header from 'components/layouts/default/Header.vue';
import Footer from 'components/layouts/default/Footer.vue';
import BasesService from 'services/basesManager.service';
import { useServerSsoAuth } from 'composables/useAuthHelper';
import { useSearchGroupAccess } from 'composables/useGroup';
import { useMainTitle } from 'composables/useMainTitle';
import { useCheckInternalUser } from 'shared/composables/useCheckInternalUser';
import setAuthCookieHeaders from '../composables/setAuthCookieHeaders';

setAuthCookieHeaders();
const basesService = inject<BasesService>(BasesService.getServiceName());

useMainTitle();
const isSearchGroupAccess = useSearchGroupAccess();
await basesService?.getPopulation();
await useSetServerUserInfo();
await useServerSsoAuth();
useCheckInternalUser();

onBeforeMount(async () => {
  await basesService?.getPopulation();
});
</script>

<style lang="scss" scoped>
.mm-main-page {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &::after {
    content: '';
    width: 100%;
    height: 10px;
    flex: 0 0 1px;
  }

  &.overflow {
    overflow: hidden;
  }

  :deep(.mm-header) {
    position: relative;
    z-index: 99;
  }
}
</style>
